<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">공통코드 관리</h1>
    <div class="box-wrap d-flex">
      <div class="box-ct">
        <v-text-field
          class="form-inp lg"
          name="name"
          label="공통코드타입"
          outlined
          hide-details
          v-model="groupCd"
          v-on:keyup.enter="getList"
        >
        </v-text-field>
        <v-text-field
          class="form-inp ml-2 lg"
          name="name"
          label="공통코드명"
          outlined
          hide-details
          v-model="cdNm"
          v-on:keyup.enter="getList"
        >
        </v-text-field>
        <!-- <div class="form-inp sm ml-2">
          <v-select
            :items="dropItems"
            item-text="name"
            item-value="value"
            outlined
            hide-details
            label="사용여부"
            placeholder="선택하세요"
            v-model="useYn"
          ></v-select>
        </div> -->
      </div>
      <div class="ml-auto align-self-center" v-on:click="getList" >
        <v-btn outlined class="btn-etc2" >조회</v-btn>
      </div>
    </div>
    <div class="d-flex">
      <div class="box-wrap col-5">
        <h2 class="tit-h2 d-flex">
          공통코드현황
          <div class="ml-auto">
            <v-btn outlined class="btn-default" v-on:click="registGroupCd" >신규</v-btn>
            <v-btn outlined class="btn-default ml-2" v-on:click="getGroupCdView" >상세</v-btn>
            <v-btn outlined class="btn-etc ml-2" v-on:click="showDeleteGroupCdConfirm" >삭제</v-btn>
          </div>
          <v-dialog v-if="dialogCOP0200" max-width="655" persistent v-model="dialogCOP0200"
            content-class="square-modal min-auto">
            <dialog-COP0200
              :param="COP0200Param"
              p01
              @hide="hideDialog('COP0200')"
              @hideAndGetList="hideAndGetList('COP0200')"
            >
            </dialog-COP0200>
          </v-dialog>

        </h2>
        <div class="box-ct">
          <v-data-table
            dense
            :headers="divGrid01Headers"
            :items="divGrid01DataText"
            :items-per-page="divGrid01ItemsPerPage"
            item-key="id"
            :page.sync="divGrid01Page"
            hide-default-footer
            class="grid-default"
            :item-class="isActiveRow"
            show-select
            height="590px"
            @page-count="divGrid01PageCount = $event"
            v-on:click:row="getListSel"
            v-model="selectedGroupCdList"
            fixed-header
            no-data-text="검색된 결과가 없습니다."
          >
          </v-data-table>
          <div class="grid-paging text-center pt-2">
            <span class="grid-total-count">총 <strong>{{ divGrid01DataText.length }}</strong>건</span>
            <v-pagination
              v-model="divGrid01Page"
              :length="divGrid01PageCount"
              :total-visible="divGrid01TotalVisible"
              next-icon="svg-paging-next"
              prev-icon="svg-paging-prev"
            ></v-pagination>
          </div>
        </div>
      </div>
      <div class="box-wrap ml-4 flex-grow-1">
        <h2 class="tit-h2 d-flex">
          상세코드현황
          <div class="ml-auto">
            <v-btn outlined class="btn-default" v-on:click="registCd" >신규</v-btn>
            <v-btn outlined class="btn-default ml-2" v-on:click="getCdView" >상세</v-btn>
            <v-btn outlined class="btn-etc ml-2" v-on:click="showDeleteCdConfirm">삭제</v-btn>
          </div>
          <v-dialog v-if="dialogCOP0300" max-width="655" persistent v-model="dialogCOP0300"
            content-class="square-modal min-auto">
            <dialog-COP0300
              :param="COP0300Param"
              p01
              @hide="hideDialog('COP0300')"
              @hideAndGetDetailList="hideAndGetDetailList('COP0300')"
            >
            </dialog-COP0300>
          </v-dialog>
        </h2>
        <div class="box-ct">
          <v-data-table
            dense
            :headers="divGrid02Headers"
            :items="divGrid02DataText"
            :items-per-page="divGrid02ItemsPerPage"
            item-key="id"
            :page.sync="divGrid02Page"
            hide-default-footer
            class="grid-default"
            height="590px"
            :item-class="isActiveRow2"
            show-select
            @page-count="divGrid02PageCount = $event"
            v-on:click:row="getDetailSel"
            v-on:dblclick:row="getCdView"
            v-model="selectedCdList"
            no-data-text="검색된 결과가 없습니다."
          >
          </v-data-table>
          <div class="grid-paging text-center pt-2">
            <span class="grid-total-count">총 <strong>{{ divGrid02DataText.length }}</strong>건</span>
            <v-pagination
                v-model="divGrid02Page"
                :length="divGrid02PageCount"
                :total-visible="divGrid02TotalVisible"
                next-icon="svg-paging-next"
                prev-icon="svg-paging-prev"
            ></v-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import DialogCOP0200 from "./COP0200.vue";
  import DialogCOP0300 from "./COP0300.vue";

  export default {
    name: "MENU_M810701", //name은 'MENU_' + 파일명 조합
    components: {
      DialogCOP0200,
      DialogCOP0300,
    },
    data() {
      return {
        groupCd: '',
        cdNm: '',
        useYn: '',
        url: '',

        dialogCOP0200: false,
        dialogCOP0300: false,
        COP0200Param:{},
        COP0300Param:{},
        menu1: false,
        menu2: false,
        menu3: false,
        menu4: false,
        timeStep: "10:10",
        timeStep2: "05:10",
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        dates: [
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substr(0, 10),
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substr(0, 10),
        ],
        radioGroup: [
          {
            text: "고객접수시작",
          },
          {
            text: "고객접수중지",
          },
        ],
        radioGroupSelected: "고객접수시작",
        dropItems: [
          { name: "전체", value:"" },
          { name: "사용", value:"Y" },
          { name: "미사용", value:"N" },
        ],
        divGrid01Headers: [
          { text: "No", value: "index", align: "center", sortable: true, width: "60px"},
          {
            text: "공통코드타입",
            value: "GROUP_CD",
            align: "center",
            sortable: true,
            width: "110px"
          },
          {
            text: "코드구분",
            value: "CD_TYPE",
            align: "center",
            sortable: true,
            width: "100px"
          },
          {
            text: "공통코드명",
            value: "CD_NM",
            align: "left",
            sortable: true,
          },
          {
            text: "정렬순서",
            value: "SORT_ORD",
            align: "center",
            sortable: true,
            width: "80px"
          },
          {
            text: "코드생성자",
            value: "REGR_ID",
            align: "center",
            sortable: true,
          },
        ],
        divGrid02Headers: [
          { text: "No", value: "index", align: "center", sortable: true },
          {
            text: "공통코드타입",
            value: "GROUP_CD",
            align: "center",
            sortable: false,
          },
          {
            text: "코드구분",
            value: "CD_TYPE",
            align: "center",
            sortable: true,
          },
          {
            text: "상세코드",
            value: "CD",
            align: "left",
            sortable: true,
          },
          {
            text: "상세코드명",
            value: "CD_NM",
            align: "left",
            sortable: true,
          },
          {
            text: "사용여부",
            value: "USE_YN_NM",
            align: "center",
            sortable: true,
          },
          {
            text: "정렬순서",
            value: "SORT_ORD",
            align: "center",
            sortable: true,
          },
          {
            text: "기타코드1",
            value: "ETC_INFO01",
            align: "center",
            sortable: true,
          },
          {
            text: "기타코드2",
            value: "ETC_INFO02",
            align: "center",
            sortable: true,
          },
          {
            text: "기타코드3",
            value: "ETC_INFO03",
            align: "center",
            sortable: true,
          },
        ],
        divGrid01DataText: [],
        divGrid01Page: 1,
        divGrid01PageCount: 0,
        divGrid01ItemsPerPage: 20,
        divGrid01TotalVisible: 10,

        divGrid02DataText: [],
        divGrid02Page: 1,
        divGrid02PageCount: 0,
        divGrid02ItemsPerPage: 20,
        divGrid02TotalVisible: 10,
        textareaRules: [(v) => v.length <= 200 || "Max 200 characters"],
        textareaValue: "200자 제한!",

        selGroupCd: {},
        selCd: {},
        selectedGroupCdList:[],
        selectedCdList:[],

        commonAlert: false,

        RDOnly:false,
      };
    },

    mounted(){
      // 헤더 초기화 및 세팅
      this.getList();
    },

    methods: {
      async getList() {
        let requestData = {
          headers: {
            SERVICE: this.initHeaders.SERVICE,
            METHOD: this.initHeaders.METHOD,
            TYPE: this.initHeaders.TYPE,
          },
          sendData:{}
        };

        // header 세팅
        requestData.headers["URL"] = "/api/setting/system/cmmn-code-manage/list";
        requestData.headers["METHOD"] = "list";
        requestData.headers["GRID_ID"] = "divGrid01";
        requestData.headers["ASYNC"] = false;

        // sendData 세팅
        requestData.sendData["GROUP_CD"] = this.groupCd;
        requestData.sendData["CD_NM"] = this.cdNm;
        requestData.sendData["USE_YN"] = 'Y';

        // 페이징 초기화 및 세팅
        this.divGrid01Page = 1;
        this.divGrid01PageCount = 0;
        this.divGrid01ItemsPerPage = 20;
        this.divGrid01TotalVisible = 10;
        this.divGrid01DataText = [];
        // 우측 상세코드 초기화 세팅
        this.divGrid02DataText = [];
        this.selCd = '';
        this.selGroupCd = '';
        let response = await this.common_postCall(requestData);
        this.getListCallBack(response);
      },

      getListCallBack(response){
        if(this.mixin_isEmpty(response) || response.HEADER.ERROR_FLAG) {
          this.common_alert(`관리자에게 문의해주세요.`, 'error');
          return false;
        }
        let data = response.DATA;
        this.divGrid01DataText = data;
        this.selectedGroupCdList = [];

        // 그리드 데이터 가공
        let idx = 1;
        let dataText = this.divGrid01DataText;
        for(let i in dataText){
          let id = dataText[i].GROUP_CD + "_" + dataText[i].CD + "_" + dataText[i].CD_TYPE;
          this.divGrid01DataText[i]["id"]= id;
          this.divGrid01DataText[i]["index"]= idx++;
        }
      },

      getListSel(item){
        // 선택한 아이템 저장
        this.selGroupCd = item;
        this.getDetailList(item);
      },

      getCdView() {
        if( this.mixin_isEmpty(this.selCd) && this.mixin_isEmpty(this.selectedCdList) ) {
          this.common_alert(`선택된 항목이 존재하지 않습니다.`, 'chk');
          return false;
        }

        if(this.mixin_isEmpty(this.selCd)) {
          this.selCd = this.selectedCdList[0];
        }

        // 상세
        this.COP0300Param = {
          headerTitle: "상세코드관리",
          data: this.selCd,
          type: "R",
        };
        this.showDialog('COP0300');
      },

      registGroupCd(){
        // 신규
        this.COP0200Param = {
          headerTitle: "공통코드관리",
          data: {},
          type: "C",
        };
        this.showDialog('COP0200');
      },

      registCd(){
        if( this.mixin_isEmpty(this.selGroupCd) ) {
          this.common_alert(`선택된 항목이 존재하지 않습니다.`, 'chk');
          return false;
        }
        // 신규
        this.COP0300Param = {
          headerTitle: "상세코드관리",
          data: this.selGroupCd,
          type: "C",
        };
        this.showDialog('COP0300');
      },

      getGroupCdView(){
        if( this.mixin_isEmpty(this.selectedGroupCdList) ) {
          this.common_alert(`선택된 항목이 존재하지 않습니다.`, 'chk');
          return false;
        }

        // 상세
        this.COP0200Param = {
          headerTitle: "상세코드관리",
          data: this.selectedGroupCdList[this.selectedGroupCdList.length - 1],
          type: "R",
        };
        this.showDialog('COP0200');
      },

      async getDetailList(item){
        let requestData = {
          headers: {
            SERVICE: this.initHeaders.SERVICE,
            METHOD: this.initHeaders.METHOD,
            TYPE: this.initHeaders.TYPE,
          },
          sendData:{}
        };

        // header 세팅
        requestData.headers["URL"] = "/api/setting/system/cmmn-code-manage/detail";
        requestData.headers["METHOD"] = "detail";
        requestData.headers["GRID_ID"] = "divGrid02";
        requestData.headers["ASYNC"] = false;

        // sendData 세팅
        requestData.sendData["GROUP_CD"] = item.GROUP_CD;

        // 그리드 데이터 초기화
        this.divGrid02DataText = [];
        this.selCd = '';

        // 페이징 초기화 및 세팅
        this.divGrid02Page= 1;
        this.divGrid02PageCount= 0;
        this.divGrid02ItemsPerPage= 20;
        this.divGrid02TotalVisible= 10;

        let response = await this.common_postCall(requestData, false);
        this.getDetailListCallBack(response);
      },

      getDetailListCallBack(response){
        if(this.mixin_isEmpty(response) || response.HEADER.ERROR_FLAG) {
          this.common_alert(`관리자에게 문의해주세요.`, 'error');
          return false;
        }else{
          let data = response.DATA;
          this.divGrid02DataText = data;
          this.selectedCdList = [];

          // 그리드 데이터 가공
          let idx = 1;
          let dataText = this.divGrid02DataText;
          for(let i in dataText){
            let id = dataText[i].GROUP_CD + "_" + dataText[i].CD + "_" + dataText[i].CD_TYPE;
            this.divGrid02DataText[i]["id"]= id;
            this.divGrid02DataText[i]["index"]= idx++;
          }
        }
      },

      getDetailSel(item){
        this.selCd = item;
      },

      hideAndGetList(type){
        this.hideDialog(type);
        this.getList();
      },

      hideAndGetDetailList(type){
        this.hideDialog(type);
        this.getDetailList(this.selGroupCd);
      },

      showDeleteGroupCdConfirm(){
        if( this.mixin_isEmpty(this.selectedGroupCdList) ){
          this.common_alert(`체크된 항목이 존재하지 않습니다.`, 'chk');
          return false;
        }
        this.common_confirm(`해당 코드정보를 삭제하시겠습니까?<br>코드타입을 삭제하면 관련 상세코드도 같이 삭제됩니다.`, this.deleteGroupCd, null, null, null, "chk");
      },

      showDeleteCdConfirm(){// 삭제
        if( this.mixin_isEmpty(this.selectedCdList) ){
          this.common_alert(`체크된 항목이 존재하지 않습니다.`, 'chk');
          return false;
        }
        this.common_confirm(`해당 코드정보를 삭제하시겠습니까?`, this.deleteCd, null, null, null, "chk");
      },

      closeAlert(){
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: false,
        });
      },

      async deleteGroupCd(){
        let deleteList = [];

        for(let i in this.selectedGroupCdList){
          let obj = {
            USE_YN: 'N',
            CD_TYPE: this.selectedGroupCdList[i].CD_TYPE,
            GROUP_CD: this.selectedGroupCdList[i].GROUP_CD,
            AMDR_DEPT_CD: 'x',
            AMDR_ID: this.user_id,
            PROC_ID: this.user_id,
            USER_ID: this.user_id,
          };
          deleteList.push(obj);
        }

        let requestData = {
          headers: {
            SERVICE: this.initHeaders.SERVICE,
            METHOD: this.initHeaders.METHOD,
            TYPE: this.initHeaders.TYPE,
          },
          sendData: {
            list : deleteList
          }
        };

        // header 세팅
        requestData.headers["URL"] = "/api/setting/system/cmmn-code-manage/delete";
        requestData.headers["METHOD"] = "delete";
        requestData.headers["ASYNC"] = false;
        requestData.headers["FUNCTION_NM"] = "deleteGroupCd";

        let response = await this.common_postCall(requestData);
        this.deleteGroupCdCallBack(response);
      },

      deleteGroupCdCallBack(response){
        if(this.mixin_isEmpty(response) || response.HEADER.ERROR_FLAG) {
          this.common_alert(`관리자에게 문의해주세요.`, 'error');
          return false;
        }
        this.common_alert(`정상처리 되었습니다.`);
        this.selectedGroupCdList = [];
        this.getList();
      },

      async deleteCd(){
        let deleteList = [];

        for(let i in this.selectedCdList){
          let obj = {
            USE_YN: this.selectedCdList[i].USE_YN,
            CD_TYPE: this.selectedCdList[i].CD_TYPE,
            GROUP_CD: this.selectedCdList[i].GROUP_CD,
            CD: this.selectedCdList[i].CD,
            AMDR_DEPT_CD: 'x',
            AMDR_ID: this.user_id,
            PROC_ID: this.user_id,
            USER_ID: this.user_id,
          };
          deleteList.push(obj);
        }

        let requestData = {
          headers: {
            SERVICE: this.initHeaders.SERVICE,
            METHOD: this.initHeaders.METHOD,
            TYPE: this.initHeaders.TYPE,
          },
          sendData: {
            list : deleteList
          }
        };

        // header 세팅
        requestData.headers["URL"] = "/api/setting/system/cmmn-code-manage/delete";
        requestData.headers["METHOD"] = "delete";
        requestData.headers["ASYNC"] = false;
        requestData.headers["FUNCTION_NM"] = "deleteCd";
        let response = await this.common_postCall(requestData);
        this.deleteCdCallBack(response);
      },

      deleteCdCallBack(response){
        if(this.mixin_isEmpty(response) || response.HEADER.ERROR_FLAG) {
          this.common_alert(`관리자에게 문의해주세요.`, 'error');
          return false;
        }

        this.common_alert(`정상처리 되었습니다.`);
        this.selectedCdList = [];
        this.getDetailList(this.selGroupCd);
      },

      isActiveRow(item){
        return item.id== this.selGroupCd.id? 'active':'';
      },

      isActiveRow2(item){
        return item.id== this.selCd.id? 'active':'';
      },

      startDate(e) {
        this.dates[0] = e;
      },

      endDate(e) {
        this.dates[1] = e;
      },

      showDialog(type) {
        this[`dialog${type}`] = true;
      },

      hideDialog(type) {
        this[`dialog${type}`] = false;
      },

      submitDialog(type) {
        this[`dialog${type}`] = false;
      },

    },

    computed: {
      dateRangeText() {
        var newStartDate = this.dates[0];
        var newEndDate = this.dates[1];
        return newStartDate + " ~ " + newEndDate;
      },
      
      initHeaders(){
        return {
          SERVICE : 'setting.system.cmmn-code-manage',
          METHOD : "",
          TYPE : 'BIZ_SERVICE',
        };
      },
    },
  };
</script>

<style>

</style>
